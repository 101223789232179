import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { AuthenticationService } from '@ih/app/client/shared/services';
import { jobLinkTracker, offlineLinkTracker, TrackerLink } from '@ih/app/client/shared/utils';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import SerializingLink from 'apollo-link-serialize';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';

import { environment } from '../environments/environment';
import { onError } from '@apollo/client/link/error';

const URL = `${environment.api.url}`;
export const offlineTracker = offlineLinkTracker;
export const jobTrackerLink = jobLinkTracker;

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
    console.error(graphQLErrors)
  }
  if (networkError) console.error(`[Network error]:`, networkError);
});
export function createApollo(
  httpLink: HttpLink,
  authenticationService: AuthenticationService
) {
  const serializingLink = new SerializingLink();
  const trackerLink = new TrackerLink();
  const cache = new InMemoryCache({
    typePolicies: {
      Api: { merge: true },
      ApiKey: { merge: true },
      App: { merge: true },
      BatchPayload: { merge: true },
      Board: { merge: true },
      BoardColumn: { merge: true },
      Card: { merge: true },
      Chart: { merge: true },
      Column: { merge: true },
      Dashboard: { merge: true },
      Data: { merge: true },
      Form: { merge: true },
      Group: { merge: true },
      GroupMember: { merge: true },
      GroupRole: { merge: true },
      GroupRolePermission: { merge: true },
      Organisation: { merge: true },
      OrganisationMember: { merge: true },
      OrganisationRole: { merge: true },
      OrganisationRolePermission: { merge: true },
      Page: { merge: true },
      Project: { merge: true },
      ProjectMember: { merge: true },
      ProjectRole: { merge: true },
      ProjectRolePermission: { merge: true },
      Question: { merge: true },
      Section: { merge: true },
      Table: { merge: true },
      User: { merge: true },
    },
  });

  persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
    },
  })
);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const auth = setContext(async (operation, context) => {
    const idToken = await authenticationService.getIdToken();

    // If user is not authenticated, return empty headers. Only allow verifyUser operation to check 
    // for existing user details
    if (idToken === null && operation.operationName!="verifyUser"){
      return {};
    } 
    return {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
  });

  // Additive composition
  // Middleware for dealing with Apollo requests - Order matters
  const link = ApolloLink.from([ 
    basic,
    auth,
    trackerLink,
    jobLinkTracker,
    offlineLinkTracker,
    serializingLink,
    new RetryLink(),
    errorLink,
    httpLink.create({ uri: URL }),
  ]);


  return {
    link,
    cache,
  };
}

@NgModule({
  exports: [HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthenticationService],
    },
  ],
})
export class GraphQLModule {}
