import { Injectable } from '@angular/core';
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ToastService } from '@ih/app/client/shared/services';
import { ProjectMember } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, take, tap } from 'rxjs';
import {
  FindAllProjectMembers,
  ResetUserProjectsList,
  SetProjects,
} from '../actions';
import { FindAllProjectsError, FindAllProjectsSuccess } from '../effects';

export interface UserProjectsStateModel {
  projectMember: ProjectMember[];
}

@State<UserProjectsStateModel>({
  name: 'userProjects',
  defaults: {
    projectMember: [],
  },
})
@Injectable()
export class UserProjectsState {
  constructor(
    private readonly apollo: Apollo,
    private readonly store: Store,
    private readonly toastService: ToastService,
  ) {}

  @Selector()
  public static getState(state: UserProjectsStateModel) {
    return state;
  }

  @Selector()
  public static projectMembers(state: UserProjectsStateModel): ProjectMember[] {
    return state.projectMember;
  }

  @Action(FindAllProjectMembers)
  public findAllProjectMembers(ctx: StateContext<UserProjectsStateModel>) {
    const userId = this.store.selectSnapshot<string>(
      (state) => state.user.user.id,
    );

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllProjectMembers($userId: String!) {
        findAllProjectMembers(request: { where: { userId: $userId } }) {
          project {
            id
            name
            description
            slug
            deleted
            photoURL
            user {
              username
            }
            groupLabel {
              name
              slug
            }
            organisationLabel {
              name
              slug
            }
          }
        }
      }
    `;

    const postsQuery = this.apollo.watchQuery<{
      findAllProjectMembers: ProjectMember[];
    }>({
      query,
      variables: {
        userId,
      },
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });
    postsQuery.refetch();
    return postsQuery.valueChanges.pipe(
      take(1),
      tap(
        (
          result: ApolloQueryResult<{ findAllProjectMembers: ProjectMember[] }>,
        ) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          ctx.dispatch(new FindAllProjectsSuccess(result));
        },
      ),
      catchError((error) => ctx.dispatch(new FindAllProjectsError(error))),
    );
  }

  @Action(FindAllProjectsSuccess)
  public findAllProjectsSuccess(
    ctx: StateContext<UserProjectsStateModel>,
    { result }: FindAllProjectsSuccess,
  ) {
    const projectMembers: ProjectMember[] = (
      result?.data?.findAllProjectMembers || []
    )
      .filter(function (projectMember) {
        if (projectMember && projectMember.project) {
          return true;
        }
        return false;
      }, [])
      .sort((a, b) => {
        if (a.project == null || b.project == null) return 0;
        return a.project.name.localeCompare(b.project.name);
      });

    ctx.dispatch(new SetProjects(projectMembers));
  }

  @Action(FindAllProjectsError)
  public findAllProjectsError(
    ctx: StateContext<UserProjectsStateModel>,
    { error }: FindAllProjectsError,
  ) {
    this.toastService.showError('Failed to fetch projects');
    console.error('Failed to fetch projects: ', error.message);
  }

  @Action(SetProjects)
  public setProjects(
    ctx: StateContext<UserProjectsStateModel>,
    { projectMember }: SetProjects,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.projectMember = projectMember;
      }),
    );
  }

  @Action(ResetUserProjectsList)
  public resetUserProjectsList(ctx: StateContext<UserProjectsStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.projectMember = [];
      }),
    );
  }
}
