import {
  AuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { RedirectGuard } from '@ih/app/client/shared/services';

const redirectLoggedOut = () => redirectUnauthorizedTo(['']);
const redirectLoggedIn = () => redirectLoggedInTo(['home']);

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedIn },
    loadChildren: () =>
      import('@ih/app/client/landing/feature').then(
        (m) => m.AppClientLandingFeatureModule
      ),
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/home/feature').then(
        (m) => m.AppClientHomeFeatureModule
      ),
  },
  {
    path: 'dashboard/:dashboardId',
    // canActivate: [AuthGuard],
    // data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/dashboard/feature').then(
        (m) => m.AppClientDashboardFeatureModule
      ),
  },
  {
    path: 'table/:tableId',
    // canActivate: [AuthGuard],
    // data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/table/feature').then(
        (m) => m.AppClientTableFeatureModule
      ),
  },
  {
    path: 'form/:formId',
    // canActivate: [AuthGuard],
    // data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/form/feature').then(
        (m) => m.AppClientFormFeatureModule
      ),
  },
  {
    path: 'app/:appId',
    // canActivate: [AngularFireAuthGuard],
    // data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/app/feature').then(
        (m) => m.AppClientAppFeatureModule
      ),
  },
  {
    path: 'wiki/:wikiId',
    // canActivate: [AngularFireAuthGuard],
    // data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/wiki/feature').then(
        (m) => m.AppClientWikiFeatureModule
      ),
  },
  {
    path: 'browse',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/browse/feature').then(
        (m) => m.AppClientBrowseFeatureModule
      ),
  },
  {
    path: 'group',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/group/feature').then(
        (m) => m.AppClientGroupFeatureModule
      ),
  },
  {
    path: 'login',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedIn },
    loadChildren: () =>
      import('@ih/app/client/login/feature').then(
        (m) => m.AppClientLoginFeatureModule
      ),
  },
  {
    path: 'organisation',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/organisation/feature').then(
        (m) => m.AppClientOrganisationFeatureModule
      ),
  },
  {
    path: 'marketplace',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/marketplace/feature').then(
        (m) => m.AppClientMarketplaceFeatureModule
      ),
  },
  {
    path: 'jobs',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/jobs/feature').then(
        (m) => m.AppClientJobsFeatureModule
      ),
  },
  {
    path: 'project',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/project/feature').then(
        (m) => m.AppClientProjectFeatureModule
      ),
  },
  {
    path: 'register',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedIn },
    loadChildren: () =>
      import('@ih/app/client/register/feature').then(
        (m) => m.AppClientRegisterFeatureModule
      ),
  },
  {
    path: 'forgot',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedIn },
    loadChildren: () =>
      import('@ih/app/client/forgot/feature').then(
        (m) => m.AppClientForgotFeatureModule
      ),
  },
  {
    path: 'reset',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedIn },
    loadChildren: () =>
      import('@ih/app/client/reset/feature').then(
        (m) => m.AppClientResetFeatureModule
      ),
  },
  {
    path: 'verify',
    loadChildren: () =>
      import('@ih/app/client/verify/feature').then(
        (m) => m.AppClientVerifyFeatureModule
      ),
  },
  {
    path: 'redirect',
    loadChildren: () =>
      import('@ih/app/client/redirect/feature').then(
        (m) => m.AppClientRedirectFeatureModule
      ),
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/user/feature').then(
        (m) => m.AppClientUserFeatureModule
      ),
  },
  {
    path: 'getting-started',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/getting-started/feature').then(
        (m) => m.AppClientGettingStartedFeatureModule
      ),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () =>
      import('@ih/app/client/terms-and-conditions/feature').then(
        (m) => m.AppClientTermsAndConditionsFeatureModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('@ih/app/client/privacy-policy/feature').then(
        (m) => m.AppClientPrivacyPolicyFeatureModule
      ),
  },
  {
    path: 'docs',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://docs.informationhub.io',
    },
  },
  {
    path: 'errors/:code',
    loadChildren: () =>
      import('@ih/app/client/errors/feature').then(
        (m) => m.AppClientErrorsFeatureModule
      ),
  },
  {
    path: '**',
    redirectTo: 'errors/404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppClientShellFeatureRoutingModule {}
