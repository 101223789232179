import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuState } from '@ih/app/client/shared/components/menu-content/data-access';
import { AppClientSharedComponentsMenuContentFeatureModule } from '@ih/app/client/shared/components/menu-content/feature';
import { AppClientSharedComponentsMenuHeaderUiModule } from '@ih/app/client/shared/components/menu-header/ui';
import { MeState, MenuDisplayState } from '@ih/app/client/shared/states';
import { IonicModule } from '@ionic/angular';
import { NgxsModule } from '@ngxs/store';
import { AppClientShellFeatureRoutingModule } from './app-client-shell-feature-routing.module';
import { AppClientShellFeatureComponent } from './app-client-shell-feature.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    AppClientShellFeatureRoutingModule,
    AppClientSharedComponentsMenuContentFeatureModule,
    AppClientSharedComponentsMenuHeaderUiModule,
    NgxsModule.forFeature([MenuState, MeState, MenuDisplayState]),
  ],
  declarations: [AppClientShellFeatureComponent],
  exports: [AppClientShellFeatureComponent],
})
export class AppClientShellFeatureModule {}
