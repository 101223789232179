import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AvatarModule } from 'ngx-avatars';
import { AppClientSharedComponentsMenuContentUiComponent } from './app-client-shared-components-menu-content-ui.component';
import {TourNgBootstrapModule,TourService} from 'ngx-ui-tour-ng-bootstrap';

@NgModule({
  imports: [CommonModule, IonicModule, RouterModule, AvatarModule, TourNgBootstrapModule ],
  declarations: [AppClientSharedComponentsMenuContentUiComponent],
  exports: [AppClientSharedComponentsMenuContentUiComponent],
  providers:[TourService],
})
export class AppClientSharedComponentsMenuContentUiModule {}
