export const environment = {
  production: false,
  pwa: true,
  firebase: {
    apiKey: 'AIzaSyDDise8laU8GwAU2PnFa_BASyOdkDO_OoE',
    authDomain: 'dev-information-hub-io.firebaseapp.com',
    projectId: 'dev-information-hub-io',
    storageBucket: 'dev-information-hub-io.appspot.com',
    messagingSenderId: '502153936561',
    appId: '1:502153936561:web:d8fe08edd26d4c8afbac90',
    measurementId: 'G-MCXGV31D42',
    webClientId:
      '502153936561-a5l0uvk5ga6cplms7v3j5icsnhtv2t3k.apps.googleusercontent.com',
    vapidKey: 'BAtkr3Gnpyi9RW5fQrjkzAVUiGoOCmEgQxeiIaYBYaXfEAGVpOefM_KTiAfxdIvW7yGyfZ9R1nslE1kF4KZeedo',
  },
  api: {
    url: 'https://dev.api.informationhub.io/graphql',
  },
};
