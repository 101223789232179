import { ApolloError } from '@apollo/client';
import { EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  constructor(private router: Router) {}

   handle(error: ApolloError) {
    if (error.graphQLErrors){
      const errors = error.graphQLErrors;

      for (const error of errors) {
        if(!error.extensions) throw error;

        if(error.extensions.code === 'FORBIDDEN') {
          this.router.navigate(['/errors', 401]);
          return EMPTY;
        }

      };
      
      return EMPTY;
    }


    throw error; // Rethrow the error if it's not handled
  }
}
