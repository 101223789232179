import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import { SetStandalone } from '../actions';

export interface UiStateModel {
  standalone: boolean;
  errors: Error[];
}

const defaults: UiStateModel = {
  standalone: false,
  errors: [],
};

@State<UiStateModel>({
  name: 'ui',
  defaults,
})
@Injectable()
export class UiState {
  @Selector()
  public static standalone(state: UiStateModel): boolean {
    return state.standalone;
  }

  @Action(SetStandalone)
  public setMe(ctx: StateContext<UiStateModel>, { standalone }: SetStandalone) {
    ctx.setState(
      produce((draft) => {
        draft.standalone = standalone;
      })
    );
  }

}
