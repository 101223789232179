import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';

import { ManuallySetStatus, SetStatus, SetSynchingStatus } from '../actions';

export interface NetworkStateModel {
  status: boolean | unknown;
  manual: boolean;
  synching: boolean;
  disable: boolean | unknown;
  lastOnline: string;
}

const defaults: NetworkStateModel = {
  status: false,
  manual: false,
  synching : false,
  disable: false,
  lastOnline: '',
};

@State<NetworkStateModel>({
  name: 'network',
  defaults,
})
@Injectable()
export class NetworkState {
  @Selector()
  public static state(state: NetworkStateModel): NetworkStateModel {
    return state;
  }

  @Selector()
  public static status(state: NetworkStateModel): boolean | unknown {
    return state.status;
  }

  @Selector()
  public static manual(state: NetworkStateModel): boolean {
    return state.manual;
  }

  @Selector()
  public static synching(state: NetworkStateModel): boolean {
    return state.synching;
  }

  @Selector()
  public static disable(state: NetworkStateModel): boolean | unknown {
    return state.disable;
  }

  @Selector()
  public static lastOnline(state: NetworkStateModel): string {
    return state.lastOnline;
  }

  @Action(SetStatus)
  public setStatus(
    ctx: StateContext<NetworkStateModel>,
    { status }: SetStatus,
  ) {
    return ctx.setState(
      produce(ctx.getState(), (state: NetworkStateModel) => {
        state.disable = !status;
        if (!state.manual) {
          state.status = status;
          state.lastOnline = new Date().toString();
        }
      }),
    );
  }

  /**
   * Toggles the synching status of the state
   * @updated 20/04/2024 - 08:17:44
   * @public
   * @param StateContext NetworkStateModel
   */
  @Action(SetSynchingStatus)
  public setSynchingStatus(ctx: StateContext<NetworkStateModel>, {synching} : SetSynchingStatus) : NetworkStateModel {
    return ctx.setState(
      produce(ctx.getState(), (state: NetworkStateModel) => {
        state.synching = false;
      }),
    );
  }

  @Action(ManuallySetStatus)
  public manuallySetStatus(ctx: StateContext<NetworkStateModel>) {
    return ctx.setState(
      produce(ctx.getState(), (state: NetworkStateModel) => {
        state.status = !state.status;
        state.manual = !state.manual;
        state.lastOnline = new Date().toString();
      }),
    );
  }
}
