import { Component, OnDestroy, OnInit } from '@angular/core';
import { RegisterAction } from '@ih/app/client/register/data-access';
import {
  GetMe,
  LoggedInAction,
  MenuDisplayState,
  SetUserAction,
} from '@ih/app/client/shared/states';
import {
  Actions,
  ofActionCompleted,
  ofActionSuccessful,
  Select,
  Store,
} from '@ngxs/store';
import { Observable, Subject, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'ih-app-client-shell-feature',
  template: `
    <ion-app>
      <ion-split-pane contentId="main">
        <ion-menu
          contentId="main"
          menuId="main"
          [ngClass]="(showMenu$ | async) === false ? 'hide-menu' : ''"
        >
          <ih-app-client-shared-components-menu-header-ui></ih-app-client-shared-components-menu-header-ui>
          <ion-content>
            <ih-app-client-shared-components-menu-content-feature
              menuId="main"
              [enableLogout]="true"
            ></ih-app-client-shared-components-menu-content-feature>
          </ion-content>
        </ion-menu>
        <ion-router-outlet id="main"></ion-router-outlet>
      </ion-split-pane>
    </ion-app>
  `,
  styles: [
    `
      .hide-menu {
        display: none;
      }
    `,
  ],
})
export class AppClientShellFeatureComponent implements OnInit, OnDestroy {
  @Select(MenuDisplayState.showMenu) showMenu$!: Observable<boolean>;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions
  ) {}

  ngOnInit() {
    this.actions$
      .pipe(
        ofActionCompleted(LoggedInAction),
        tap(() => {
          this.actions$
            .pipe(
              ofActionCompleted(SetUserAction),
              tap(() => {
                const registering = this.store.selectSnapshot<boolean>(
                  (state) => state.authentication.registering
                );
                if (registering) {
                  this.actions$
                    .pipe(
                      ofActionSuccessful(RegisterAction),
                      tap(() => {
                        this.store.dispatch(new GetMe());
                      }),
                      take(1)
                    )
                    .subscribe();
                } else {
                  this.store.dispatch(new GetMe());
                }
              }),
              take(1)
            )
            .subscribe();
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
