import { Component, EventEmitter, Input, Output} from '@angular/core';
import { MenuContext, MenuItem } from '@ih/app/client/shared/interfaces';
import { ActionsExecuting } from '@ngxs-labs/actions-executing';
//important for menu context its the menu content templarte
import {
  TourStepService,
} from '@ih/app/client/shared/services'; 
import { Router } from '@angular/router';

@Component({
  selector: 'ih-app-client-shared-components-menu-content-ui',
  template: `
  <tour-step-template> </tour-step-template>
    <ion-list lines="none" [hidden]="busy">
      <ion-menu-toggle auto-hide="false" [menu]="menuId">
        <ng-container *ngIf="enableContext">
          <ng-container *ngFor="let menuContext of menuContexts">
            <ion-item lines="full">
              <ion-avatar slot="start" *ngIf="menuContext.type === 'user'">
                <ngx-avatars
                  [name]="menuContext.title"
                  [src]="menuContext.img"
                  [routerLink]="menuContext.settingLink"
                  size="40"
                >
                </ngx-avatars>
              </ion-avatar>
              <ion-thumbnail slot="start" *ngIf="menuContext.type !== 'user'">
                <ngx-avatars
                  [name]="menuContext.title"
                  [src]="menuContext.img"
                  [routerLink]="menuContext.settingLink"
                  size="56"
                  [round]="false"
                >
                </ngx-avatars>
              </ion-thumbnail>
              <ion-label>
                <h2>{{ menuContext.title }}</h2>
                <p *ngIf="menuContext.type === 'user'">
                  {{ menuContext.username }}
                </p>
                <ng-container *ngIf="menuContext.type === 'group'">
                  <p *ngIf="menuContext.organisationSubTitle">
                    <a [routerLink]="menuContext.organisationSubLink">{{
                      menuContext.organisationSubTitle
                    }}</a>
                  </p>
                </ng-container>
                <ng-container *ngIf="menuContext.type === 'project'">
                  <p *ngIf="menuContext.organisationSubTitle">
                    <a [routerLink]="menuContext.organisationSubLink">{{
                      menuContext.organisationSubTitle
                    }}</a>
                  </p>
                  <p *ngIf="menuContext.groupSubTitle">
                    <a [routerLink]="menuContext.groupSubLink">{{
                      menuContext.groupSubTitle
                    }}</a>
                  </p>
                  <p *ngIf="menuContext.userSubTitle">
                    <a [routerLink]="menuContext.userSubLink">{{
                      menuContext.userSubTitle
                    }}</a>
                  </p>
                </ng-container>
              </ion-label>
            </ion-item>
          </ng-container>
        </ng-container>

        <!-- TODO: uncomment once implemted getting started -->
        <!-- <ion-item button [routerLink]="['/getting-started']">
          <ion-icon name="rocket-outline" slot="start"></ion-icon>
          <ion-label>Getting Started</ion-label>
        </ion-item> -->
                    
        <ng-container *ngFor="let item of menuItems; trackBy: identify">
          <!--
            [routerLinkActiveOptions]
            setted to true to fix routerlinkactive css issue
           -->
          <!-- <div *ngIf="item.show" [tourAnchor]= "item.tourAnchorId"> -->
          <ion-item
              button
              [routerLink]="item.link"
              routerDirection="root"
              routerLinkActive="active-item"
              [routerLinkActiveOptions]="{ exact: true }"
              *ngIf="item.show"
              
            >
              <!-- <div tourAnchor="{{ item.tourAnchorId }}"> -->
              <ion-icon [name]="item.icon" slot="start"></ion-icon>
              <ion-label>{{ item.label }}</ion-label>
                  <!-- </div> -->
            </ion-item>
          <!-- </div> -->
          
        </ng-container>

        <!-- <ion-item button *ngIf="enableLogout" (click)="logoutClick.emit()">
          <ion-icon name="log-out-outline" slot="start"></ion-icon>
          <ion-label>Logout</ion-label>
        </ion-item> -->
        <ion-item
              *ngIf="hasTourStep()"
              button
              (click)="startTour()"
              tourAnchor="hello"              
            >
              <!-- <div tourAnchor="{{ item.tourAnchorId }}"> -->
              <ion-icon name="help-circle-outline" slot="start"></ion-icon>
              <ion-label>{{ "Tutorial" }}</ion-label>
                  <!-- </div> -->
            </ion-item>

        <ion-item
            button
            routerLink="/docs"
        >
              <!-- <div tourAnchor="{{ item.tourAnchorId }}"> -->
              <ion-icon name="document-text-outline" slot="start"></ion-icon>
              <ion-label>{{ "Documentation" }}</ion-label>
                  <!-- </div> -->
            </ion-item>
      </ion-menu-toggle>

      <!-- <ion-button fill="outline" color="primary" class="chat-menu-item ion-padding-horizontal" (click)="chatClick.emit()">
        <ion-icon name="chatbubbles-outline" slot="icon-only" class="ion-margin-end"></ion-icon>
        <ion-label>Chat</ion-label>
      </ion-button> -->
    </ion-list>
    <ion-list *ngIf="busy">
      <ion-item>
        <ion-thumbnail slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-thumbnail>
        <ion-label>
          <h3>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h3>
            <ion-skeleton-text animated></ion-skeleton-text>
          </h3>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h3>
            <ion-skeleton-text animated></ion-skeleton-text>
          </h3>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h3>
            <ion-skeleton-text animated></ion-skeleton-text>
          </h3>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h3>
            <ion-skeleton-text animated></ion-skeleton-text>
          </h3>
        </ion-label>
      </ion-item>
    </ion-list>


   
    
  `,
  styles: [
    `
      ion-list {
        /* 100% so menu does not show black background on dark mode */
        height: 100%;
      }

      ngx-avatars:hover {
        cursor: pointer;
      }

      .chat-menu-item {
        position: absolute;
        bottom: 1em;
        width: 100%;
      }

      /*
        Temporary solution for when window height is less than 690px, menu items will be hidden.
        Auto to remove the the previous 100% so ion-content allows scroll to happen when menu higher than window.
        Find new fix when or if more menu items are added
      */
      @media only screen and (max-height: 690px) {
        ion-list {
          height: auto;
        }
      }
    `,
  ],
})
export class AppClientSharedComponentsMenuContentUiComponent {
  @Input() menuId = '';
  @Input() menuItems!: MenuItem[] | null;
  @Input() menuContexts!: MenuContext[] | null;
  @Input() enableLogout = false;
  @Input() enableContext = true;
  @Input() busy!: ActionsExecuting;
  @Output() logoutClick: EventEmitter<Event> = new EventEmitter();
  @Output() chatClick: EventEmitter<Event> = new EventEmitter();
  constructor(public tourStepService: TourStepService,  private router: Router,) {};
  
  startTour(): void {
    const currentRoute = this.router.url;

    this.tourStepService.triggerAll(currentRoute);
  }

  hasTourStep(): boolean {
    return this.tourStepService.isRegistered(this.router.url);
  }

  // Index required for trackby
  identify(index: number, item: MenuItem) {
    return item.label;
  }
}
